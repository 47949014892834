import FormInput from '@/donationForm/_dependencies/components/shared/FormInput'
import useRequiredComponent from './useRequiredComponent'
import { useTranslations } from 'next-intl'
import type { AddressFieldsProps } from './types'

function CityField({ required }: AddressFieldsProps) {
  const t = useTranslations('nextjs.donate.attributes')
  const FieldComponent = useRequiredComponent(required)
  const label = t('city')
  return <FieldComponent name="city" title={label} as={FormInput} />
}

export default CityField
