class MaxCalculator {
  static defaults = [
    { upto: 500, max: 500 },
    { upto: 1000, max: 1000 },
    { upto: 1500, max: 1000 },
    { upto: 2000, max: 1500 },
    { upto: 2500, max: 1500 },
    { upto: 3000, max: 2000 },
    { upto: 4000, max: 2000 },
    { upto: 5000, max: 2500 },
    { upto: 8000, max: 4000 },
    { upto: 10000, max: 4000 },
    { upto: 15000, max: 6000 },
    { upto: 20000, max: 6000 },
    { upto: 30000, max: 12000 },
    { upto: 40000, max: 12000 },
    { upto: 50000, max: 15000 },
    { upto: 100000, max: 18000 },
    { upto: Infinity, max: 21000 },
  ]
  static max(amount: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Math.min(this.defaults.find((e) => e.upto >= amount)!.max, amount)
  }
}

export default MaxCalculator
