'use client'

import CityField from './CityField'
import CountryField from './CountryField'
import StreetField from './StreetField'
import ZipField from './ZipField'
import useCityAutoFill from './useCityAutofill'
import { AddressFieldsProps } from './types'
import { LayoutStack } from '@betterplace/design-system/client'

function AddressFields({ required }: AddressFieldsProps) {
  const onBlur = useCityAutoFill()

  return (
    <LayoutStack space="400">
      <CountryField required={required} />
      <ZipField onBlur={onBlur} required={required} />
      <CityField required={required} />
      <StreetField required={required} />
    </LayoutStack>
  )
}

export default AddressFields
