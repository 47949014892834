'use client'

import { FormTextareaProps } from './types'
import { Textarea } from '@betterplace/design-system/client'
import { forwardRef } from 'react'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import type { DonationFormValues } from '@/donationForm/types'
import type { FieldPath } from 'react-hook-form'

const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(function FormTextArea(
  { className, ...props }: FormTextareaProps,

  ref
) {
  const name = props.name as FieldPath<DonationFormValues>
  const { formState } = useDonationFormContext()
  const { errors, touchedFields } = formState
  const error = touchedFields[name] ? errors[name]?.message : undefined
  return <Textarea {...props} ref={ref} error={error} />
})

export default FormTextArea
