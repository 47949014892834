import { TextInput } from '@betterplace/design-system/client'
import { forwardRef } from 'react'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import type { DonationFormValues } from '@/donationForm/types'
import type { FieldPath } from 'react-hook-form'
import type { FormInputProps } from './types'

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(function FormInput(
  { className, ...props }: FormInputProps,
  ref
) {
  const name = props.name as FieldPath<DonationFormValues>
  const { formState } = useDonationFormContext()
  const { errors, touchedFields } = formState
  const error = touchedFields[name] ? errors[name]?.message : undefined

  return <TextInput {...props} type="text" ref={ref} className={className} error={error} />
})

export default FormInput
