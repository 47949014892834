import NavigationArrow from './NavigationArrow'
import styles from './styles.module.css'

import I18nHtml from '@/components/I18nHtml'
import { A11y, Mousewheel, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslations } from 'next-intl'

function CodonationReasons() {
  const t = useTranslations('nextjs')

  return (
    <div className={styles.reasonsWrapper}>
      <NavigationArrow id="reason-prev" direction="left" title={t('core.previous')} disabledClass="is-disabled" />
      <Swiper
        modules={[Navigation, Mousewheel, A11y]}
        slidesPerView={1}
        pagination={false}
        navigation={{
          nextEl: `#reason-next`,
          prevEl: `#reason-prev`,
          disabledClass: 'is-disabled',
        }}
        spaceBetween={16}
        roundLengths
        mousewheel={{ forceToAxis: true }}
        a11y={{
          prevSlideMessage: t('core.previous'),
          nextSlideMessage: t('core.next'),
        }}
      >
        <SwiperSlide key={1} className={styles.reason}>
          <I18nHtml i18nKey="nextjs.donate.codonation_reasons.reason_1_html" />
        </SwiperSlide>
        <SwiperSlide key={2} className={styles.reason}>
          <I18nHtml i18nKey="nextjs.donate.codonation_reasons.reason_2_html" />
        </SwiperSlide>
        <SwiperSlide key={3} className={styles.reason}>
          <I18nHtml i18nKey="nextjs.donate.codonation_reasons.reason_3_html" />
        </SwiperSlide>
      </Swiper>
      <NavigationArrow id="reason-next" direction="right" title={t('core.next')} disabledClass="is-disabled" />
    </div>
  )
}

export default CodonationReasons
