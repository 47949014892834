import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import FormInput from '../../../shared/FormInput'
import styles from './YoutubeFields.module.css'
import { ChangeEventHandler, FocusEventHandler, useCallback } from 'react'
import { Fieldset, Heading, LayoutStack } from '@betterplace/design-system/client'
import { getHHmmssTimestampFromSeconds } from '@/helpers/utils'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function tryToFixTimestamp(timestamp: string) {
  let fixedTimestamp = timestamp
  if (fixedTimestamp.match(/^[0-9]+(:[0-9]+){1,2}$/)) {
    const timestampArray = fixedTimestamp.split(':')
    fixedTimestamp = timestampArray.map((e) => (e.match(/^[0-9]{1}$/) ? '0' + e : e)).join(':')
  }
  if (fixedTimestamp.match(/^[0-9]{2}:[0-9]{2}$/)) {
    return '00:' + fixedTimestamp
  }
  return fixedTimestamp
}

function getSecondsFromTParam(tParamValue: string) {
  if (tParamValue.match(/^[0-9]+$/)) return Number(tParamValue)
  if (!tParamValue.match(/^([0-9]+[hms])+$/)) return null

  let totalSeconds = 0
  tParamValue.split(/(?<=[^0-9])/).forEach((element) => {
    if (element.match(/^[0-9]+s$/)) totalSeconds += Number(element.slice(0, -1))
    if (element.match(/^[0-9]+m$/)) totalSeconds += Number(element.slice(0, -1)) * 60
    if (element.match(/^[0-9]+h$/)) totalSeconds += Number(element.slice(0, -1)) * 3600
  })
  return totalSeconds
}

function getParsedUrlWithStartTime(url: string) {
  try {
    // eslint-disable-next-line no-var
    var parsedUrl = new URL(url)
  } catch {
    return [null, null] as const
  }

  const params = parsedUrl.searchParams
  const tParamValue = params.get('t')
  if (!tParamValue) return [parsedUrl.toString(), null] as const

  const totalSeconds = getSecondsFromTParam(tParamValue)
  if (!totalSeconds) return [parsedUrl.toString(), null] as const

  params.delete('t')
  return [parsedUrl.toString(), totalSeconds] as const
}

function YoutubeFields() {
  const t = useTranslations('nextjs.donate')
  const { showStreamingShareableMedia } = useDonationFormConfig()
  const { setValue } = useDonationFormContext()
  const setVideoStartTime = useCallback(
    (value: string) => {
      setValue('video_start_time', value, { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  const onVideoLinkChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const url = target.value
      if (!url) {
        setVideoStartTime('00:00:00')
        return
      }
      const [parsedUrl, startTimeSeconds] = getParsedUrlWithStartTime(url)
      if (!startTimeSeconds) return

      target.value = parsedUrl
      const videoStartTime = getHHmmssTimestampFromSeconds(startTimeSeconds)
      setVideoStartTime(videoStartTime)
    },
    [setVideoStartTime]
  )
  const onVideoStartTimeInputLeave: FocusEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const userInput = target.value
      if (!userInput) {
        setVideoStartTime('00:00:00')
        return
      }
      if (userInput.match(/^[0-9]{1,6}$/)) {
        setVideoStartTime(getHHmmssTimestampFromSeconds(Number(userInput)))
      }
      if (!userInput.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
        const fixedTimestamp = tryToFixTimestamp(userInput)
        if (fixedTimestamp !== userInput) setVideoStartTime(fixedTimestamp)
      }
    },
    [setVideoStartTime]
  )
  if (!showStreamingShareableMedia) return null
  return (
    <LayoutStack>
      <Fieldset
        className={styles.youtubeFieldset}
        layoutProps={{
          space: '400',
        }}
        legend={
          <Heading level="h200" className={styles.youtubeHeading}>
            {t('youtube_fields.headline')}
          </Heading>
        }
        layout="vertical"
      >
        <DonationFormField
          description={t('youtube_fields.video_link_hint')}
          name="video_link"
          type="url"
          as={FormInput}
          label={t('attributes.video_link')}
          onChange={onVideoLinkChange}
        />
        <DonationFormField
          name="video_start_time"
          description={t('youtube_fields.video_start_time_hint')}
          as={FormInput}
          label={t('attributes.video_start_time')}
          defaultValue="00:00:00"
          placeholder="hh:mm:ss"
          onBlur={onVideoStartTimeInputLeave}
        />
      </Fieldset>
    </LayoutStack>
  )
}

export default YoutubeFields
