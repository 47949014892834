'use client'
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Image from 'next/image'
import itz from './itz.svg'
import paypal from './paypal.svg'
import sslLogo from './ssl_logo.png'
import styles from './trust_logos.module.css'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { SSLLogoProps } from './types'
import { Tooltip } from '@betterplace/design-system/client'
import { useActionFormValues } from '@/form/ActionForm'
import { useTranslations } from 'next-intl'

function PaymentMethodLogo() {
  const [paymentMethod] = useActionFormValues(['payment_method'])
  let logo: string | null = null
  let alt: string = ''
  if (paymentMethod === PaymentMethods.Paypal) {
    logo = paypal as string
    alt = 'PayPal Logo'
  }
  return logo && <Image src={logo} className={styles.trustLogo} alt={alt} width={135} height={36} />
}

export function SSLLogo({ className }: SSLLogoProps) {
  const t = useTranslations('nextjs.donate.trust_logos')
  return (
    <Tooltip content={t('ssl_tooltip')} id="tooltip-for-ssl-logo">
      <Image src={sslLogo} className={className} alt="SSL Logo" style={{ width: 'auto' }} />
    </Tooltip>
  )
}

export default function TrustLogos() {
  return (
    <div className={styles.trustLogos}>
      <Image src={itz} className={styles.trustLogo} alt="Initiative Transparente Zivilgesellschaft Logo" />
      <SSLLogo className={styles.trustLogo} />
      <PaymentMethodLogo />
    </div>
  )
}
