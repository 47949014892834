import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import { ZodIssueCode } from 'zod'
import { entriesOf, stringify } from '@betterplace/utils'
import { getTranslatorWithFallback } from '@/i18n'
import { useCallback } from 'react'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'
import type { ErrorHandler } from '@/form/ActionForm/useActionForm'
import type { NextIntlKeys, Translator } from '@/i18n/types'
function isATranslationKey(type: unknown) {
  return !['validate', 'required', ...Object.values(ZodIssueCode)].includes(type as string)
}
function useErrorHandler(): ErrorHandler<DonationFormValues> {
  const t_ = useTranslations('nextjs.donate.errors')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const handler: ErrorHandler<DonationFormValues> = useCallback(
    (errors, values, { setValue, setBusy, setError }, options = {}) => {
      if (options.shouldSetErrors) {
        const t = getTranslatorWithFallback<Translator<'nextjs.donate.errors'>>(t_)
        // TODO: This is kind of insane, we should probably move the translation logic to the server, as the issue is with the server error translation messages
        for (const [key, error] of entriesOf(errors)) {
          const { message: message_, type: type_ } = error!
          let message = message_ as string
          if (type_ && isATranslationKey(type_)) {
            message = t(type_ as NextIntlKeys<'nextjs.donate.errors'>, { defaultValue: message })
          }
          if (message === undefined || message === null) continue
          setError(key, { message, type: 'server' })
          if (key === 'root') continue
          // @ts-expect-error for whatever reason we can get empty keys here TODO: check why and fix
          if (key === '') continue
          const value = values[key]
          setValue(key, value, { shouldTouch: true })
        }
      }
      setValue('_step', 1)
      setBusy(false)
      const { receiver_id, receiver_type, show_codonation, channel } = values
      analytics.donationErrorMessageDisplayed({
        donation_form_type: getDonationFormType({
          receiverId: receiver_id,
          receiverType: receiver_type,
          channel: channel,
          showCodonation: !!show_codonation,
        }),
        id: receiver_id,
        receiver_type: receiver_type,
        error: stringify(errors),
        errors: errors,
      })
    },
    [t_]
  )

  return handler
}

export default useErrorHandler
