export type GoogleTagManagerProps = {
  gtmId: string | undefined
  gtmAuth: string | undefined
  gtmPreview: string | undefined
}
export type HotjarProps = { hotjarId: string | undefined }
export type EnvProps = { environment: string; domain: string }

export type ClientCookieBannerProps = EnvProps & { serverSideCookieValue: TrackingCookieType } & GoogleTagManagerProps &
  HotjarProps
export type TrackingCookieType = 'accepted' | 'rejected' | 'edit' | null | undefined

export const TRACKING_COOKIE_NAME = 'betterplace-tracking-accepted'
