'use client'
import FormInput from '@/donationForm/_dependencies/components/shared/FormInput'
import RequiredField from '@/donationForm/_dependencies/components/shared/RequiredField'
import { LayoutCluster, LayoutStack } from '@betterplace/design-system/client'
import { useTranslations } from 'next-intl'

import styles from './IFrameHolderFields.module.css'

function IFrameHolderFields() {
  const t = useTranslations()

  return (
    <LayoutStack space="400">
      <LayoutCluster space="300" className={styles.nameContainer}>
        <RequiredField name="first_name" title={t('nextjs.donate.attributes.first_name')} as={FormInput} />
        <RequiredField name="last_name" title={t('nextjs.donate.attributes.last_name')} as={FormInput} />
      </LayoutCluster>
      <RequiredField name="email" type="email" title={t('nextjs.donate.attributes.email')} as={FormInput} />
    </LayoutStack>
  )
}

export default IFrameHolderFields
