import { useEffect, useRef } from 'react'

type UseIntervalCallback = (signal?: AbortSignal) => void | Promise<void>
type UseIntervalProps<TCallback extends UseIntervalCallback> = {
  callback: TCallback
  executeImmediately?: boolean
  intervalMs?: number
  passAbortSignal?: boolean
}

function executeCallback<TFunction extends UseIntervalCallback>(callback: TFunction, signal?: AbortSignal) {
  const result = callback(signal)
  if (result && 'then' in result) {
    void result.then()
  }
}

function useInterval<TCallback extends UseIntervalCallback>({
  callback,
  executeImmediately,
  intervalMs = 1000,
  passAbortSignal,
}: UseIntervalProps<TCallback>) {
  const controllerRef = useRef<AbortController | null>(null)
  useEffect(() => {
    if (typeof callback !== 'function') return
    if (passAbortSignal) controllerRef.current = new AbortController()
    if (executeImmediately) executeCallback(callback, controllerRef.current?.signal)
    const interval = setInterval(() => {
      controllerRef.current?.abort()
      if (passAbortSignal) controllerRef.current = new AbortController()
      executeCallback(callback, controllerRef.current?.signal)
    }, intervalMs)
    return () => {
      controllerRef.current?.abort()
      clearInterval(interval)
    }
  }, [callback, executeImmediately, intervalMs, passAbortSignal])
}

export default useInterval
