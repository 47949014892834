'use client'

import CityField from '../../formParts/AddressFields/CityField'
import CountryField from '../../formParts/AddressFields/CountryField'
import StreetField from '../../formParts/AddressFields/StreetField'
import ZipField from '../../formParts/AddressFields/ZipField'
import styles from './IFrameAddressFields.module.css'
import useCityAutoFill from '../../formParts/AddressFields/useCityAutofill'
import { AddressFieldsProps } from '../../formParts/AddressFields/types'
import { LayoutCluster, LayoutStack } from '@betterplace/design-system/client'

function IFrameAddressFields({ required }: AddressFieldsProps) {
  const onBlur = useCityAutoFill()

  return (
    <LayoutStack space="400">
      <StreetField required={required} />
      <LayoutCluster space="300" className={styles.cityContainer}>
        <CityField required={required} />
        <ZipField onBlur={onBlur} required={required} />
      </LayoutCluster>
      <CountryField required={required} />
    </LayoutStack>
  )
}

export default IFrameAddressFields
