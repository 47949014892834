'use client'
import classNames from 'classnames'
import styles from './Messages.module.css'
import useMessageContext from './useMessageContext'
import { Alert, LayoutStack } from '@betterplace/design-system/client'
import { MessagesProps } from '.'

function Messages({ className }: MessagesProps) {
  const { messages } = useMessageContext()

  if (!messages.length) return null

  return (
    <LayoutStack className={classNames(styles.messages, className)}>
      {messages.map(({ type, id, message }) => (
        <Alert key={id} kind={type} className={styles.alertMessage}>
          {message}
        </Alert>
      ))}
    </LayoutStack>
  )
}

export default Messages
