'use client'
import CodonationCalculator from '@/donationForm/_dependencies/CodonationCalculator'
import Image from 'next/image'
import ZeroCodonationMessage, { ZeroCodonationMessageContextProvider } from '../ZeroCodonationMessage'
import classNames from 'classnames'
import styles from './CodonationSelection.module.css'
import useCodonationModalState from '../../useCodonationModalState'
import useLocale from '@/i18n/useLocale'
import {
  AmountField,
  Button,
  Heading,
  Icon,
  LayoutCluster,
  LayoutModal,
  LayoutStack,
} from '@betterplace/design-system/client'
import { type ModalContentProps, type PredefinedAmountBoxProps } from './types'
import { formatCents, parseCents, toLowerCase } from '@betterplace/utils'
import { postMessageScrollTop } from '@/helpers/utils'
import { useCallback, useMemo } from 'react'
import { useCodonationValue, useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

const images = [
  'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-3-1e395bb471ac74d84b464698d679d7f786c51da262e3cb5ffb7cc2ada7c45193.png',
  'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-5-1ded43de393f6436c062886bf2ee7ae4e5b419286adb4de4d6157ff83aefa453.png',
  'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-7-7d86dc933b3fbfdbe829c3c1ea92033d201578227d412c9893f409755e6a5605.png',
] as const

function ModalContent({ handleClose }: ModalContentProps) {
  const locale = useLocale()
  const t = useTranslations('nextjs.donate.codonation_selection.codonation_modal')
  const { wording, carrierName, carrierProfilePicture } = useDonationFormConfig()
  const [amountCents] = useDonationFormValues(['amount_cents'])
  const [value, setValue, commitValue] = useCodonationValue()
  const prefills = useMemo(() => CodonationCalculator.prefills(amountCents), [amountCents])
  const storeAndClose = useCallback(() => {
    commitValue()
    handleClose()
  }, [handleClose, commitValue])

  const handlePredefinedAmountSelection: PredefinedAmountBoxProps['onClick'] = useCallback(
    (cents) => {
      setValue(cents)
      storeAndClose()
    },
    [setValue, storeAndClose]
  )

  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key !== 'Enter') return
      storeAndClose()
    },
    [storeAndClose]
  )

  const onValueChanged: (event: { target: HTMLInputElement }) => void = useCallback(
    ({ target }) => {
      setValue(parseCents(target.value))
    },
    [setValue]
  )

  return (
    <div className={styles.codonationModalContent}>
      <LayoutStack space="400">
        <p className={styles.introduction}>{t(`${toLowerCase(wording)}.platform_introduction`)}</p>

        <Heading level="h200">
          <span>{t(`${toLowerCase(wording)}.call_to_action`)}:</span>
        </Heading>

        <LayoutCluster space="100" className={styles.amountBoxesContainer}>
          <PredefinedAmountBox cents={prefills[0]} imageSrc={images[2]} onClick={handlePredefinedAmountSelection} />
          <PredefinedAmountBox cents={prefills[1]} imageSrc={images[1]} onClick={handlePredefinedAmountSelection} />
          <PredefinedAmountBox cents={prefills[2]} imageSrc={images[0]} onClick={handlePredefinedAmountSelection} />
        </LayoutCluster>

        <div className={styles.codonationInputWrapper}>
          <AmountField
            label={t('amount_label')}
            name="codonation_cents"
            id="codonation-input"
            value={value / 100}
            onChange={onValueChanged}
            onBlur={onValueChanged}
            onKeyUp={handleEnter}
            inputSize="large"
            step="1"
            max={amountCents / 100}
            hideSteps
            locale={locale}
          >
            <Icon name="euroSign" title="Euro" color="fg-content-primary" />
          </AmountField>
          <Button onClick={storeAndClose} className={classNames(styles.codonateButton, 'btn')}>
            {t('change_amount_button')}
          </Button>
        </div>
        <ZeroCodonationMessage value={value} />
        {carrierName && (
          <div className={styles.organisation}>
            {carrierProfilePicture && (
              <Image src={carrierProfilePicture} alt="" width={100} height={100} style={{ height: 'auto' }} />
            )}
            <p className={styles.organisationTeaser}>
              {t(`${toLowerCase(wording)}.trust_teaser`, {
                carrier_name: carrierName,
              })}
            </p>
          </div>
        )}
      </LayoutStack>
    </div>
  )
}

function PredefinedAmountBox({ cents, imageSrc, onClick: onClick_ }: PredefinedAmountBoxProps) {
  const locale = useLocale()
  const t = useTranslations('nextjs.donate.codonation_selection.codonation_modal')
  const onClick = useCallback(() => onClick_(cents), [cents, onClick_])
  return (
    <button type="button" className={styles.predefinedAmountBox} onClick={onClick}>
      <LayoutStack space="300" className={styles.predefinedAmountContainer}>
        {formatCents(locale, cents)}
        <div className={styles.predefinedImageWrapper}>
          <Image src={imageSrc} alt="" fill className={styles.predefinedImage} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button as="a" href="javascript:void(0)" className={classNames(styles.fakeButton, 'btn')} tabIndex={-1}>
          {t('choose_button')}
        </Button>
      </LayoutStack>
    </button>
  )
}

function CodonationSelection() {
  const t = useTranslations('nextjs')
  const { wording, receiverName } = useDonationFormConfig()
  const { openModal, hideModal, modalShown } = useCodonationModalState()

  return (
    <div className={styles.codonationSelectionContainer}>
      <button
        className={classNames('simulated-link', styles.openCodonationModalButton)}
        type="button"
        onClick={openModal}
      >
        {t('donate.codonation_selection.codonation_modal.open_button')}
      </button>
      <ZeroCodonationMessageContextProvider>
        <LayoutModal
          id="codonation-selection-modal"
          onClose={hideModal}
          isOpen={modalShown}
          onOpen={postMessageScrollTop}
          title={t(`donate.codonation_selection.codonation_modal.${toLowerCase(wording)}.headline`, {
            receiver_name: receiverName,
          })}
          closeText={t('core.close')}
          hideActions
          classNames={{ modal: styles.modal, modalInner: styles.modalInner }}
        >
          <ModalContent handleClose={hideModal} />
        </LayoutModal>
      </ZeroCodonationMessageContextProvider>
    </div>
  )
}

export default CodonationSelection
