import { MessagesContextType } from './types'
import { createContext } from 'react'
import { noop } from 'lodash'

const MessagesContext = createContext<MessagesContextType>({
  messages: [],
  addMessage: () => '',
  removeMessage: noop,
  reset: noop,
} as MessagesContextType)
export default MessagesContext
