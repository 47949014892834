'use client'
import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import { DonationIntervals } from '@betterplace/api-graphql-types'
import { LayoutCluster, RadioButton, Tooltip } from '@betterplace/design-system/client'
import { toLowerCase } from '@betterplace/utils'
import { useDonationFormConfig, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

const intervals = [DonationIntervals.Single, DonationIntervals.Monthly, DonationIntervals.Yearly]

function IFrameIntervalSelection({ labelClass }: { labelClass?: string }) {
  const t = useTranslations()
  const { showRecurring, recurringPaymentMethods } = useDonationFormConfig()
  const [paymentMethod] = useDonationFormValues(['payment_method'])
  if (!showRecurring) {
    return null
  }

  const isRecurringPaymentMethod = recurringPaymentMethods.includes(paymentMethod)

  return (
    <LayoutCluster space="300">
      {intervals.map((interval) => {
        const disabled = !isRecurringPaymentMethod && interval !== DonationIntervals.Single
        return (
          <Tooltip key={interval} content={t('nextjs.donate.recurring_disabled_tooltip')} inactive={!disabled}>
            <DonationFormField
              name="interval"
              key={disabled ? undefined : interval}
              type="radio"
              as={RadioButton}
              className={labelClass}
              value={interval}
              disabled={disabled}
            >
              {t(`nextjs.donate.recurring_interval.${toLowerCase(interval)}`)}
            </DonationFormField>
          </Tooltip>
        )
      })}
    </LayoutCluster>
  )
}

export default IFrameIntervalSelection
