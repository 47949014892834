'use client'

import Captcha from '@/components/Captcha'
import { useCallback, useEffect, useState } from 'react'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import type { CaptchaProps } from '@/components/Captcha/types'

const CAPTCHA_SOLUTION_FIELD_NAME = 'captcha_solution'
const CAPTCHA_ERROR_FIELD_NAME = '_captcha_error'
const CAPTCHA_COMPLETE = '_captcha_complete'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useFakeCaptcha() {
  const [isFakeCaptcha, setIsFakeCaptcha] = useState(true)
  useEffect(() => {
    const isFake = document.body.classList.contains('env-test')
    setIsFakeCaptcha(isFake)
    if (!isFake) return
  }, [])
  return isFakeCaptcha
}

function DonationFormCaptcha() {
  const [backendSubmitCount, setBackendSubmitCount] = useState<number>(0)
  const [backendErrorCount, setBackendErrorCount] = useState<number>(0)
  const { captchaSiteKey, captchaEndpoint } = useDonationFormConfig()
  const { setValue, formState } = useDonationFormContext()
  const { isValid, isSubmitting, errors } = formState
  const onCaptchaSuccess: CaptchaProps['onSuccess'] = useCallback(
    (solution) => {
      setValue(CAPTCHA_COMPLETE, true, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
      setValue(CAPTCHA_SOLUTION_FIELD_NAME, solution, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    },
    [setValue]
  )

  const onCaptchaError: CaptchaProps['onError'] = useCallback(
    (error, value) => {
      setValue(CAPTCHA_COMPLETE, true)
      setValue(CAPTCHA_ERROR_FIELD_NAME, value)
      console.error(error)
    },
    [setValue]
  )

  const onCaptchaReset = useCallback(() => {
    // this causes a problem, because it invalidates the form again
    // and therefore replaces the backend error
    // therefore we cannot have two different error messages
    // until we rebuild the whole form error handling
    setValue(CAPTCHA_COMPLETE, false)
  }, [setValue])

  useEffect(() => {
    if (isValid && isSubmitting) {
      setBackendSubmitCount(backendSubmitCount + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isSubmitting])

  useEffect(() => {
    // We want to reset the Captcha when we get an error from the backend,
    // because we don't want the user (bot) to be able to simply re-submit the form
    // and friendly captcha would complain about a duplicate solution.
    // However, in all other cases, we don't want to add more hassle for the user than necessary.

    // we only want to know about the backend errors so we have to filter out the captcha validation error
    const { _captcha_complete, ...errorsWithoutCaptchaComplete } = errors
    if (backendSubmitCount > 0 && Object.keys(errorsWithoutCaptchaComplete).length) {
      setBackendErrorCount(backendErrorCount + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendSubmitCount])

  const isFakeCaptcha = useFakeCaptcha()

  if (isFakeCaptcha) {
    return null
  }

  return (
    <>
      <Captcha
        siteKey={captchaSiteKey}
        endpoint={captchaEndpoint}
        onSuccess={onCaptchaSuccess}
        onError={onCaptchaError}
        onReset={onCaptchaReset}
        resetTrigger={backendErrorCount}
        fieldName={CAPTCHA_SOLUTION_FIELD_NAME}
      />
    </>
  )
}

export default DonationFormCaptcha
