import DonationFormField from '@/donationForm/_dependencies/components/shared/DonationFormField'
import RequiredField from '@/donationForm/_dependencies/components/shared/RequiredField'
import { useMemo } from 'react'

function useRequiredComponent(required?: boolean) {
  const FieldComponent = useMemo(() => (required ? RequiredField : DonationFormField), [required])
  return FieldComponent
}

export default useRequiredComponent
