import Image from 'next/image'
import MaxCalculator from './MaxCalculator'
import Modal from '@/donationForm/_dependencies/components/shared/Modal'
import ZeroCodonationMessage, { ZeroCodonationMessageContextProvider } from '../../../formParts/ZeroCodonationMessage'
import classNames from 'classnames'
import styles from './CodonationSelection.module.css'
import useCodonationModalState from '../../../useCodonationModalState'
import useLocale from '@/i18n/useLocale'
import { AmountField, Button, Icon } from '@betterplace/design-system/client'
import { RangeInput } from '@betterplace/design-system/server'
import { formatCents, parseCents } from '@betterplace/utils'
import { useCallback, useMemo } from 'react'
import { useCodonationValue, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type { ModalContentProps } from './types'

function CodonationFlowers({ value, maxAmount }: { value: number; maxAmount: number }) {
  const currentPercentage = useMemo(() => (value / maxAmount) * 100, [value, maxAmount])
  const images: {
    showImage: boolean
    src: string
  }[] = [
    {
      showImage: 0 <= currentPercentage && currentPercentage < 3,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-0-e39b5c5747e825e6b78fae47f6f991347a2e6465cb9aedcdba89296f258b2c77.png',
    },
    {
      showImage: 3 <= currentPercentage && currentPercentage < 8,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-1-aca140a3b2a74ad65be7ce93422afc37e6ca001ce93c44ee97aea72c8e9509d5.png',
    },
    {
      showImage: 8 <= currentPercentage && currentPercentage < 14,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-2-e91118ed0da282016c6dfafbfbb205a1b543f21a658188b277bd1431fed08933.png',
    },
    {
      showImage: 14 <= currentPercentage && currentPercentage < 22,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-3-1e395bb471ac74d84b464698d679d7f786c51da262e3cb5ffb7cc2ada7c45193.png',
    },
    {
      showImage: 22 <= currentPercentage && currentPercentage < 34,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-4-22c82f7f9f6efc1fe94657b80103052c7428e34652e1985a41bbcb23db5ffe46.png',
    },
    {
      showImage: 34 <= currentPercentage && currentPercentage < 48,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-5-1ded43de393f6436c062886bf2ee7ae4e5b419286adb4de4d6157ff83aefa453.png',
    },
    {
      showImage: 48 <= currentPercentage && currentPercentage < 66,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-6-6ec510b3fa5a8afe50817f351c9b5f1db73079922dab0a7176b50e13f02bf3ff.png',
    },
    {
      showImage: 66 <= currentPercentage && currentPercentage < 88,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-7-7d86dc933b3fbfdbe829c3c1ea92033d201578227d412c9893f409755e6a5605.png',
    },
    {
      showImage: 88 <= currentPercentage,
      src: 'https://betterplace-assets.betterplace.org/assets/donations/codonation/slider/level-8-b2971854e78cf9c0f1c31bca71813e81447a7b5ec3639e36b8356c1ec5787ee2.png',
    },
  ]

  return (
    <>
      {images.map((image, index) => (
        <Image
          className={classNames(styles.image, { [styles.visibleImage as string]: image.showImage })}
          src={image.src}
          key={index}
          alt=""
          fill
        />
      ))}
    </>
  )
}

function ModalContent({ handleClose }: ModalContentProps) {
  const t = useTranslations()
  const locale = useLocale()
  const [amountCents] = useDonationFormValues(['amount_cents'])
  const maxAmount = MaxCalculator.max(amountCents) / 100

  const [value, setValue, commitValue] = useCodonationValue()

  const storeAndClose = useCallback(() => {
    if (commitValue()) handleClose()
  }, [handleClose, commitValue])

  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key !== 'Enter') return
      storeAndClose()
    },
    [storeAndClose]
  )

  const onValueChanged: (event: { target: HTMLInputElement }) => void = useCallback(
    ({ target }) => {
      setValue(parseCents(target.value))
    },
    [setValue]
  )

  return (
    <div>
      <p>{t('nextjs.donate.codonation_box.modal_text')}</p>

      <div className={styles.codonationFormWrapper}>
        <div className={styles.illustration}>
          <CodonationFlowers value={value} maxAmount={maxAmount * 100} />
        </div>

        <div className={styles.slider}>
          <RangeInput
            onChange={onValueChanged}
            value={value / 100}
            max={maxAmount}
            min={0}
            aria-label={t('nextjs.donate.attributes.codonation')}
          />
        </div>

        <div className={styles.inputAndSum}>
          <div className={styles.inputFlexContainer}>
            <div className={styles.inputWrapper}>
              <AmountField
                label={t('nextjs.donate.attributes.codonation')}
                id="codonation-input"
                name="codonation_cents"
                value={value / 100}
                onChange={onValueChanged}
                onBlur={onValueChanged}
                onKeyUp={handleEnter}
                inputSize="large"
                step="1"
                max={amountCents / 100}
                invisibleLabel
                hideSteps
                locale={locale}
              >
                <Icon name="euroSign" title="Euro" color="fg-content-secondary" id="codonation_cents-suffix" />
              </AmountField>
            </div>
          </div>

          <div>&nbsp;=&nbsp;</div>

          <div className={styles.sum}>
            {t('nextjs.donate.codonation_box.sum_label')}&nbsp;
            {formatCents(locale, Math.round(value) + amountCents, { precision: 2 })}
          </div>
        </div>
        <ZeroCodonationMessage value={value} />
        <Button kind="primary" type="button" onClick={storeAndClose} className={styles.closeCodonationModalButton}>
          {t('nextjs.donate.codonation_box.apply_button')}
        </Button>
      </div>
    </div>
  )
}

function CodonationSelection() {
  const locale = useLocale()
  const t = useTranslations('nextjs')
  const { modalShown, openModal, hideModal, codonationCents } = useCodonationModalState()

  return (
    <div>
      <div className={styles.modalTrigger}>
        <button onClick={openModal} type="button" className={styles.fakeInput}>
          <div>{formatCents(locale, codonationCents, { format: '%v' })}</div>
          <Icon name="euroSign" title="Euro" color="fg-content-secondary" />
        </button>
        <Button onClick={openModal} kind="secondary" type="button">
          {t('donate.codonation_box.trigger_button')}
        </Button>
      </div>
      <ZeroCodonationMessageContextProvider>
        <Modal
          id="codonation-selection-modal"
          onClose={hideModal}
          isOpen={modalShown}
          title={t('donate.codonation_box.modal_headline')}
          closeText={t('core.close')}
          hideActions
          fullWidth
        >
          <ModalContent handleClose={hideModal} />
        </Modal>
      </ZeroCodonationMessageContextProvider>
    </div>
  )
}

export default CodonationSelection
