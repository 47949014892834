import classNames from 'classnames'
import styles from './ProhibitedNotice.module.css'
import { useTranslations } from 'next-intl'

function ProhibitedNotice() {
  const t = useTranslations('nextjs.donate')
  return (
    <>
      <div className="donate-iframe-header">
        <div className="donate-iframe-headline">
          <p className="line1">{t('prohibited_headline')}</p>
        </div>
      </div>
      <div className="donate-iframe-content">
        <div className={classNames('max-w-screen-xs', styles.prohibitedNoticeText)}>{t('prohibited_notice')}</div>
      </div>
    </>
  )
}

export default ProhibitedNotice
