import CountryList from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import useLocale from '@/i18n/useLocale'
import useRequiredComponent from './useRequiredComponent'
import { Select } from '@betterplace/design-system/client'
import { memo } from 'react'
import { useTranslations } from 'next-intl'
import type { AddressFieldsProps } from './types'
import type { Locale } from '@/i18n/types'

CountryList.registerLocale(de)
CountryList.registerLocale(en)

const CountryFieldOptions = memo(function CountryFieldOptions({ locale }: { locale: Locale }) {
  const countries = CountryList.getNames(locale)
  return Object.keys(countries).map((alpha2) => (
    <option value={alpha2} key={alpha2}>
      {countries[alpha2]}
    </option>
  ))
})

function CountryField({ required }: AddressFieldsProps) {
  const locale = useLocale()
  const FieldComponent = useRequiredComponent(required)
  const t = useTranslations('nextjs.donate.attributes')
  const label = t('country')
  return (
    <div>
      <FieldComponent name="country_alpha2" title={label} as={Select}>
        <CountryFieldOptions locale={locale} />
      </FieldComponent>
    </div>
  )
}
export default CountryField
