import classNames from 'classnames'
import styles from './styles.module.css'
import { LayoutModal, LayoutModalProps } from '@betterplace/design-system/client'

function Modal({ fullWidth, ...props }: Omit<LayoutModalProps, 'classNames'> & { fullWidth?: boolean }) {
  return (
    <LayoutModal
      {...props}
      classNames={{
        modal: classNames(styles.donateFormModal, { [styles.donateFormModalWide!]: fullWidth }),
        modalInner: classNames({ [styles.donateFormModalInnerWide!]: fullWidth }),
        titleContainer: styles.donateFormTitle,
        contentContainer: styles.donateFormModalContent,
      }}
    />
  )
}

export default Modal
