import analytics from '@betterplace/product-analytics'
import { TrackingCookieType } from './types'
import { useEffect } from 'react'

function useAnalytics(status: TrackingCookieType | undefined) {
  useEffect(() => {
    if (status === 'accepted') {
      return analytics.optIn()
    }
    if (status === 'rejected') {
      return analytics.optOut()
    }
    return analytics.optInOutPending()
  }, [status])

  useEffect(() => {
    return () => {
      analytics.cancel()
    }
  }, [])
}

export default useAnalytics
