'use client'
import ErrorMessage from '../ErrorMessage'
import { Checkbox } from '@betterplace/design-system/client'
import { forwardRef } from 'react'
import type { DonationFormValues } from '@/donationForm/types'
import type { FieldPath } from 'react-hook-form'
import type { FormCheckboxProps } from './types'

const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(function FormCheckbox(
  { label, ...props }: FormCheckboxProps,
  ref
) {
  const id = props.id ?? props.name
  return (
    <div className={props.className}>
      <Checkbox {...props} ref={ref} id={id}>
        {label}
      </Checkbox>
      <ErrorMessage name={props.name as FieldPath<DonationFormValues>} />
    </div>
  )
})

export default FormCheckbox
