'use client'

import autosize from 'autosize'
import { useEffect } from 'react'

function useTextareaAutosize(textarea: HTMLTextAreaElement | null) {
  useEffect(() => {
    if (textarea) {
      autosize(textarea)
    }
  }, [textarea])
}

export default useTextareaAutosize
