/* eslint-disable import/exports-last */
import { z } from 'zod'

const youtubeRegEx =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

const timeStampRegEx = /^\d{2}:[0-5][0-9]:[0-5][0-9]$/

export const timeStampSchema = z.string().regex(timeStampRegEx)
export const youtubeLinkSchema = z.string().url().regex(youtubeRegEx)

const emptyVideoFields = z.object({
  video_link: z.literal('').optional(),
  video_start_time: z.literal('00:00:00').optional(),
})

const mandatoryVideoFields = z.object({
  video_link: youtubeLinkSchema,
  video_start_time: timeStampSchema,
})

export const videoFields = mandatoryVideoFields.or(emptyVideoFields)
