import I18nHtml from '@/components/I18nHtml'
import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import styles from './ZeroCodonationMessage.module.css'
import { useDonationFormConfig } from '@/donationForm/_dependencies/helpers'
import { useEffect } from 'react'
import { useZeroCodonationMessageContext } from './ZeroCodonationMessageContext'

function ZeroCodonationMessage({ value }: { value: number }) {
  const { receiverId, receiverType, channel, showCodonation } = useDonationFormConfig()
  const { shown, show } = useZeroCodonationMessageContext()

  useEffect(() => {
    if (value !== 0) return
    analytics.coDonationSetToZero({
      id: receiverId,
      receiver_type: receiverType,
      donation_form_type: getDonationFormType({
        receiverId,
        receiverType,
        channel,
        showCodonation,
      }),
    })
    if (shown) return
    show()
  }, [channel, receiverId, receiverType, show, showCodonation, shown, value])

  if (!shown) return null
  return (
    <I18nHtml
      className={styles.zeroCodonationMessage}
      i18nKey="nextjs.donate.codonation_box.zero_codonation_message_html"
      aria-live="polite"
    />
  )
}

export default ZeroCodonationMessage
