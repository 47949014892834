'use client'

import Image, { StaticImageData } from 'next/image'
import styles from './MatchingEventNotice.module.css'
import wirWunderLogo from '@betterplace/assets/images/logos/wirwunder_logo.svg'
import { LogoTextBannerProps, type MatchingEventNoticeProps } from './types'

import Modal from '../../../shared/Modal'
import ModalContent from './ModalContent'
import TrustedHtml from '@/components/TrustedHtml'
import { useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'

function LogoTextBanner({ backgroundColor, color, logo: logoUrl, logoAlt, children }: LogoTextBannerProps) {
  return (
    <div className={styles.logoTextBanner} style={{ backgroundColor, color }} data-testid="logo-text-banner">
      <Image src={logoUrl} alt={logoAlt ?? ''} className={styles.logo} height={36} width={100} />
      <div className={styles.content}>{children}</div>
    </div>
  )
}

function MatchingEventNotice({
  matchingEvent: { providedAmountInCents, backgroundColor, branchLogo, color, logo, bannerContent, modalInfo },
  donateButton,
}: MatchingEventNoticeProps) {
  const t = useTranslations('nextjs')
  const [show, setShow] = useState(false)
  const onHide = useCallback(() => setShow(false), [])
  const handleClick = useCallback(() => {
    setShow(true)
  }, [])

  if (!providedAmountInCents) return null

  return (
    <>
      <LogoTextBanner backgroundColor={backgroundColor} color={color} logo={logo ?? (wirWunderLogo as StaticImageData)}>
        <TrustedHtml as="span" value={bannerContent} className={styles.bannerTextContent} />
        <button onClick={handleClick} className={styles.gentleLink}>
          {t('donate.matching_event.banner_read_conditions')}
        </button>
      </LogoTextBanner>
      <Modal isOpen={show} onClose={onHide} title={modalInfo.headline} closeText={t('core.close')} hideActions>
        <ModalContent
          backgroundColor={backgroundColor}
          branchLogo={branchLogo}
          modalInfo={modalInfo}
          onHide={onHide}
          donateButton={donateButton}
          logo={logo}
        />
      </Modal>
    </>
  )
}

export default MatchingEventNotice
