import DonationAmountSum from '@/donationForm/_dependencies/components/shared/DonationAmountSum'
import styles from './PlatformFormDonationAmountSum.module.css'
import { useTranslations } from 'next-intl'

export function PlatformFormDonationAmountSum() {
  const t = useTranslations('nextjs.donate.codonation_box')
  return (
    <div className={styles.amountSumContainer}>
      <span className={styles.sumLabelContainer}>
        <span className={styles.sumLabel}>{t('sum_label')}</span>
        <DonationAmountSum fields={['amount_cents', 'codonation_cents']} />
      </span>
      <span className={styles.thankYouText}>{t('thank_you')}</span>
    </div>
  )
}

export default PlatformFormDonationAmountSum
