'use client'
import FormInput from '@/donationForm/_dependencies/components/shared/FormInput'
import RequiredField from '@/donationForm/_dependencies/components/shared/RequiredField'
import { useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function CompanyFields() {
  const t = useTranslations('nextjs.donate.attributes')
  const [companyDonation] = useDonationFormValues(['_company_donation'])
  if (!companyDonation) return null

  return <RequiredField name="company_name" title={t('company_name')} as={FormInput} />
}

export default CompanyFields
