'use client'

import styles from './GeneralError.module.css'
import useLocale from '@/i18n/useLocale'
import { getTranslatorWithFallback } from '@/i18n'
import { keysOf } from '@betterplace/utils'
import { scrollToRef } from '@/helpers/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDonationFormConfig, useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'
import type { FieldError, FieldErrors, FieldPath } from 'react-hook-form'
import type { NextIntlKeys } from '@/i18n/types'

// eslint-disable-next-line import/exports-last
export function findFirstInvalidInput(errors?: FieldErrors<DonationFormValues> | undefined): HTMLElement | null {
  if (!errors) return null
  let minPosition = Number.POSITIVE_INFINITY
  let result: HTMLElement | null = null
  const fields = keysOf(errors)
  for (const field of fields) {
    const input: HTMLElement | null = document.querySelector(`label[for="${field}"]`)
    if (!input) continue
    if (!input.offsetParent) continue
    const position = input.getBoundingClientRect().top
    if (position < minPosition) {
      minPosition = position
      result = input
    }
  }
  return result
}

/* TODO: Ultimately it'd be best to move most of the logic into the useErrorHandler hook,
  especially when scrolling is concerned, we need to solve the insanity of translating BE errors first however
*/
function GeneralError() {
  const t_ = useTranslations()
  const t = useMemo(() => getTranslatorWithFallback(t_), [t_])
  const locale = useLocale()
  const { formState } = useDonationFormContext()
  const { layout } = useDonationFormConfig()
  const [message, setMessage] = useState<string | undefined>()
  const generalErrorRef = useRef<HTMLDivElement | null>(null)
  const firstInvalidFieldRef = useRef<HTMLElement | null>(null)
  const submitCountHandledRef = useRef(0)
  const toSentence = useCallback(
    (...words: (string | number)[]): string => {
      const and = locale === 'de' ? 'und' : 'and'

      switch (words.length) {
        case 0:
          return ``
        case 1:
          return `${words[0]!}`
        case 2:
          return `${words[0]!} ${and} ${words[1]!}`
        default:
          return `${words.slice(0, -1).join(', ')} ${and} ${[...words].pop()!}`
      }
    },
    [locale]
  )

  const localizeFieldName = useCallback(
    (field: FieldPath<DonationFormValues> | 'root') =>
      t(`nextjs.donate.attributes.${field}` as NextIntlKeys, { defaultValue: '' }),
    [t]
  )

  const buildErrorMessage = useCallback(
    (errors: FieldErrors<DonationFormValues> | undefined): string | undefined => {
      if (!errors) return
      if (errors.root?.message) return errors.root.message
      if (
        errors.stripe_token?.message &&
        /The payment is blocked due to a high likelihood of chargeback/.test(errors.stripe_token.message)
      )
        return t('nextjs.errors.messages.stripe_try_again')
      if (errors.client_reference?.message) return t('nextjs.donate.errors.client_reference_taken')
      if (errors.captcha_solution?.message) return t('nextjs.donate.errors.verification_error')
      if (errors._captcha_complete?.message) return t('nextjs.donate.errors.captcha_required')
      const fields = keysOf(errors).map(localizeFieldName).filter(Boolean)
      if (!fields.length) return
      const summaryTranslationKey = layout === 'donate/iframe' ? 'summary_formal' : 'summary'
      return t(`nextjs.errors.messages.${summaryTranslationKey}`, { fields: toSentence(...fields) })
    },
    [layout, localizeFieldName, t, toSentence]
  )

  useEffect(() => {
    const { isSubmitting, isSubmitted, submitCount, isValid, errors: errors_, isValidating, touchedFields } = formState
    firstInvalidFieldRef.current = null
    if (!isSubmitted) return
    if (isValidating || isSubmitting) return
    const currentSubmitCountHandled = submitCountHandledRef.current
    submitCountHandledRef.current = submitCount
    if (currentSubmitCountHandled === submitCount) return
    let errors: typeof errors_ | undefined = undefined
    if (!isValid) {
      const fields = keysOf(errors_)
      if (fields.length) {
        errors = {}
        for (const field of fields) {
          if (field !== 'root' && !touchedFields[field]) continue
          errors[field] = errors_[field] as FieldError
        }
      }
    }
    firstInvalidFieldRef.current = findFirstInvalidInput(errors)
    const message = buildErrorMessage(errors)
    setMessage(message)
  }, [formState, setMessage, buildErrorMessage])

  useEffect(() => {
    if (!message) return
    const ref = firstInvalidFieldRef.current ? firstInvalidFieldRef : generalErrorRef
    scrollToRef(ref)
  }, [message])

  return <div ref={generalErrorRef}>{message && <div className={styles.errorText}>{message}</div>}</div>
}

export default GeneralError
