import { TrackingCookieType } from './types'
import { useCallback, useEffect, useRef, useState } from 'react'

function useAutoReject(rejectTracking: () => void, isModalOpen: boolean, trackingCookieValue: TrackingCookieType) {
  const [pageUsed, setPageUsed] = useState<boolean>(false)
  const [userScrolled, setUserScrolled] = useState(false)

  const bannerRef = useHandleClickOutside(() => setPageUsed(true))

  const handleUserScroll = useCallback(() => {
    if (!pageUsed && !userScrolled) {
      setUserScrolled(true)
      document.removeEventListener('scroll', handleUserScroll)
    } else if (!pageUsed && userScrolled) {
      setPageUsed(true)
    }
  }, [pageUsed, userScrolled])

  useEffect(() => {
    document.addEventListener('scroll', handleUserScroll)

    return () => {
      document.removeEventListener('scroll', handleUserScroll)
    }
  }, [handleUserScroll])

  useEffect(() => {
    if (!pageUsed || isModalOpen || trackingCookieValue) return
    const timer = setTimeout(() => rejectTracking(), 10000)
    return () => clearTimeout(timer)
  }, [pageUsed, isModalOpen, rejectTracking, trackingCookieValue])

  return bannerRef
}

function useHandleClickOutside(callback: () => void) {
  const targetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
        callback()
      }
    }
    document.addEventListener('click', handleOnClickOutside, true)

    return () => {
      document.removeEventListener('click', handleOnClickOutside, true)
    }
  }, [callback, targetRef])

  return targetRef
}

export default useAutoReject
