'use client'

import ClientForm from './ClientForm'
import IFrameForm from './IFrameForm'
import PlatformForm from './PlatformForm'
import useDonationForm from './useDonationForm'
import { ActionFormProvider } from '@/form/ActionForm'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { type Stripe, loadStripe } from '@stripe/stripe-js'

import useLocale from '@/i18n/useLocale'
import useUpdateCodonationOnDonationChange from '@/donationForm/_dependencies/components/DonationForm/useUpdateCodonationOnDonationChange'
import { StripeContextProvider } from '@/donationForm/_dependencies/StripeContext'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMessageContext } from '@/components/Messages'
import { useOfflineMessage } from '@/helpers/hooks'
import { useTranslations } from 'next-intl'
import { withRetry } from '@/helpers/utils'
import type { DonationFormProps } from '@/donationForm/types'

function FormWithStripeContext(props: DonationFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  // const value = useMemo(() => ({ stripe, elements }), [stripe, elements])
  return (
    <StripeContextProvider value={{ stripe, elements }}>
      <FormSetup {...props} />
    </StripeContextProvider>
  )
}

function useStripePromise(stripeKey: string) {
  const [promise, setPromise] = useState<Promise<Stripe | null> | null>(null)
  const { addMessage, removeMessage } = useMessageContext()
  const messageIdRef = useRef<string | null>(null)
  const removeErrorMessage = useCallback(
    () => messageIdRef.current && removeMessage(messageIdRef.current),
    [removeMessage]
  )
  const t = useTranslations('nextjs')
  const onError = useCallback(
    (attempt: number) => {
      removeErrorMessage()
      messageIdRef.current = addMessage({
        type: 'error',
        message: `${t('donate.errors.stripe_loading')} ${t('errors.messages.retrying', { attempt })}`,
      })
    },
    [addMessage, removeErrorMessage, t]
  )

  const onFailure = useCallback(() => {
    removeErrorMessage()
    messageIdRef.current = addMessage({
      type: 'error',
      message: t('donate.errors.stripe_loading'),
    })
  }, [addMessage, removeErrorMessage, t])

  const loadStripeWithRetry = useMemo(
    () => withRetry(loadStripe, { onError, onFailure, onSuccess: removeErrorMessage, baseDelayMs: 2500 }),
    [onError, onFailure, removeErrorMessage]
  )

  useEffect(() => {
    if (typeof window === 'undefined' || !stripeKey) return
    setPromise(loadStripeWithRetry(stripeKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeKey])
  return promise
}

function Form(props: DonationFormProps) {
  useUpdateCodonationOnDonationChange()
  switch (props.config.layout) {
    case 'donate/platform':
      return <PlatformForm {...props} />
    case 'donate/iframe':
      return <IFrameForm {...props} />
    default:
      return <ClientForm {...props} />
  }
}

// eslint-disable-next-line import/exports-last
export function FormSetup({ config, initialFFs, initialFormValues }: DonationFormProps) {
  const { action, onSubmit, id, ...providerProps } = useDonationForm(initialFormValues)

  return (
    <ActionFormProvider {...providerProps}>
      <Form
        config={config}
        action={action}
        onSubmit={onSubmit}
        id={id}
        initialFFs={initialFFs}
        initialFormValues={initialFormValues}
      />
    </ActionFormProvider>
  )
}

function DonationForm(props: DonationFormProps) {
  const stripePromise = useStripePromise(props.config.stripePublicKey)
  const locale = useLocale()
  useOfflineMessage()
  return (
    <Elements
      stripe={stripePromise}
      // TODO: Enable this if we are sure it is allowed to use google fonts and add 'fonts.googleapis.com' to CONNECT_SRC_VALUES
      // options={{
      //   fonts: [
      //     {
      //       cssSrc: 'https://fonts.googleapis.com/css?family=Fira+Sans',
      //     },
      //   ],
      // }}
      options={{ locale }}
    >
      <FormWithStripeContext {...props} />
    </Elements>
  )
}

export default DonationForm
