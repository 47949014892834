/* eslint-disable import/exports-last */
import { HttpMethod, InferResponseTypeFromUrl } from '@betterplace/api-v4-types'
import { Locale } from '@/i18n/types'

interface APIv4Body<T> extends Body {
  json(): Promise<T>
}
export type APIv4RequestInit<T extends HttpMethod = 'get'> = RequestInit & { method?: T; locale: Locale }
export interface APIv4Response<T> extends Omit<Response, 'json'>, APIv4Body<T> {}
const API_URL = process.env.NEXT_PUBLIC_BETTERPLACE_API_URL
function fetchAPIv4<TUrl extends string, TMethod extends HttpMethod = 'get'>(
  path: TUrl,
  { locale, ...init }: APIv4RequestInit<TMethod> = { locale: 'de' }
) {
  const url = `${API_URL}/${locale}/api_v4${path}`
  return fetch(url, init).catch((e) => {
    console.error(e)
    throw e
  }) as Promise<APIv4Response<InferResponseTypeFromUrl<TMethod, TUrl>>>
}

export default fetchAPIv4
