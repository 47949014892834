import { I18nHtmlProps } from './types'
import { type ReactElement, type ReactNode, createElement, useMemo } from 'react'
import { useTranslations } from 'next-intl'
import type { NextIntlKeys } from '@/i18n/types'

const SUPPORTED_GENERIC_TAGS = [
  'strong',
  'span',
  'i',
  'p',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'ol',
  'ul',
  'li',
] as const

type SUPPORTED_GENERIC_TAGS = (typeof SUPPORTED_GENERIC_TAGS)[number]
type RenderFn = (chunks: ReactNode) => ReactElement

const defaultRenderers = SUPPORTED_GENERIC_TAGS.reduce(
  (acc, tag) => {
    acc[tag] = (chunks: React.ReactNode) => createElement(tag, {}, chunks)
    return acc
  },
  {} as Record<SUPPORTED_GENERIC_TAGS, RenderFn>
)

function I18nHtml<TargetKey extends NextIntlKeys>({
  i18nKey,
  as = 'span',
  options = {},
  ...props
}: I18nHtmlProps<TargetKey>) {
  const t = useTranslations()
  const value = useMemo(
    () =>
      t.rich(i18nKey, {
        ...defaultRenderers,
        a: (chunks) => {
          const attrs = options.target && options.target === '_blank' ? { rel: 'noopener noreferrer' } : {}
          return (
            <a href={options.href as string} target={options.target as string} {...attrs}>
              {chunks}
            </a>
          )
        },
        br: () => <br />,
        ...options,
      }),
    [t, i18nKey, options]
  )
  const Tag = as
  return <Tag {...props}>{value}</Tag>
}

export default I18nHtml
