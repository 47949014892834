import useDonationFormContext from '@/donationForm/_dependencies/helpers/useDonationFormContext'
import useDonationFormValues from '@/donationForm/_dependencies/helpers/useDonationFormValues'
import { useCallback, useEffect, useRef, useState } from 'react'

function useCodonationValue() {
  const [codonationCents] = useDonationFormValues(['codonation_cents'])
  const { setValue: setFormValue } = useDonationFormContext()
  const valueRef = useRef(codonationCents)
  const [value, internalSetValue] = useState(codonationCents)
  const commitValue = useCallback(() => {
    setFormValue('codonation_cents', valueRef.current, {
      shouldTouch: true,
      shouldValidate: true,
      shouldDirty: true,
    })
    return true
  }, [setFormValue])

  const setValue = useCallback((value: number) => {
    internalSetValue(value)
    valueRef.current = value
  }, [])

  useEffect(() => {
    setValue(codonationCents)
  }, [codonationCents, setValue])

  const reset = useCallback(() => {
    setValue(codonationCents)
  }, [codonationCents, setValue])
  return [value, setValue, commitValue, reset] as const
}

export default useCodonationValue
