'use client'
import Link from 'next/link'
import { HeaderCompact } from '@betterplace/design-system/page-parts'
import { useTranslations } from 'next-intl'

function Header() {
  const t = useTranslations('nextjs.header')
  return (
    <HeaderCompact
      Link={Link}
      text={{
        homePageUrl: process.env.NEXT_PUBLIC_BETTERPLACE_URL,
        logoAlt: t('betterplace_logo_alt_text'),
        claim: t('claim'),
      }}
    />
  )
}

export default Header
