'use client'
import { createContext, useContext } from 'react'
import type { StripeOptions } from '@/donationForm/types'

const context = createContext<StripeOptions>({})

export function useStripeContext() {
  return useContext(context)
}

export function useIsStripeLoaded() {
  const { stripe } = useStripeContext()
  return !!stripe
}

export const StripeContextProvider = context.Provider
