import { type ReactNode, createContext, useCallback, useContext, useState } from 'react'

const ZeroCodonationContext = createContext({ shown: false, show: () => {} })

export function useZeroCodonationMessageContext() {
  return useContext(ZeroCodonationContext)
}

export function ZeroCodonationMessageContextProvider({ children }: { children: ReactNode }) {
  const [shown, setShown] = useState(false)
  const show = useCallback(() => setShown(true), [])
  return <ZeroCodonationContext.Provider value={{ shown, show }}>{children}</ZeroCodonationContext.Provider>
}
