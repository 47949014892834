class CodonationCalculator {
  static defaults = [
    { upto: 500, suggest: 100, prefills: [200, 50, 25] },
    { upto: 1000, suggest: 200, prefills: [300, 100, 50] },
    { upto: 1500, suggest: 300, prefills: [400, 200, 100] },
    { upto: 1900, suggest: 400, prefills: [500, 200, 100] },
    { upto: 2500, suggest: 500, prefills: [500, 300, 200] },
    { upto: 3000, suggest: 500, prefills: [700, 400, 200] },
    { upto: 4000, suggest: 600, prefills: [700, 500, 300] },
    { upto: 5000, suggest: 900, prefills: [900, 500, 400] },
    { upto: 8000, suggest: 900, prefills: [1200, 700, 500] },
    { upto: 10000, suggest: 1200, prefills: [1500, 900, 500] },
    { upto: 15000, suggest: 2000, prefills: [3000, 1000, 500] },
    { upto: 20000, suggest: 2000, prefills: [3000, 1000, 500] },
    { upto: 30000, suggest: 4000, prefills: [5000, 2000, 1000] },
    { upto: 40000, suggest: 4000, prefills: [5000, 2000, 1000] },
    { upto: 50000, suggest: 5000, prefills: [7000, 2000, 1000] },
    { upto: 100000, suggest: 6000, prefills: [8000, 5000, 2000] },
    { upto: Infinity, suggest: 7000, prefills: [10000, 5000, 2000] },
  ] as const

  static suggest(amount: number) {
    return this.defaults.find((e) => e.upto >= amount)!.suggest
  }

  static prefills(amount: number) {
    return this.defaults.find((e) => e.upto >= amount)!.prefills
  }
}

export default CodonationCalculator
