import sanitize from 'sanitize-html'
import { TrustedHtmlProps } from './types'
import { useMemo } from 'react'
/**
 * Use this component for and only for inserting HTML we can trust.
 */
function TrustedHtml({ value, as = 'span', ...props }: TrustedHtmlProps) {
  const Tag = as
  const __html = useMemo(() => value && sanitize(value), [value])
  return <Tag dangerouslySetInnerHTML={{ __html }} {...props} />
}

export default TrustedHtml
