import useFinalizeHandler from './useFinalizeHandler'
import useTrackDonationFormVisit from './useTrackDonationFormVisit'
import { donationFormSchema, getDonationFormErrorMap } from '@/donationForm/_dependencies/schemas'
import { keysOf } from '@betterplace/utils'
import { processDonationFormAction } from '@/donationForm/_dependencies/operations/processDonationAction'
import { sendGTMEvent } from '@next/third-parties/google'
import { useActionForm } from '@/form/ActionForm'
import { useCallback, useEffect } from 'react'
import { useErrorHandler } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'
import type { ErrorHandler } from '@/form/ActionForm/useActionForm'

function useDonationForm(defaultValues: DonationFormValues, id?: string) {
  const t = useTranslations()

  useTrackDonationFormVisit()

  const afterClientSubmitSuccess = useCallback(({ payment_method }: DonationFormValues) => {
    sendGTMEvent({
      event: 'eeCheckout',
      ecommerce: { currencyCode: 'EUR', checkout: { actionField: { step: 2, option: payment_method } } },
    })
  }, [])

  const afterSubmitSuccess = useFinalizeHandler(t)
  const afterSubmitError = useErrorHandler()
  const afterClientSubmitError: ErrorHandler<DonationFormValues> = useCallback((errors, values, tools) => {
    // TODO: This seems super hacky, I'm copying legacy behaviour, but there should be a better way to achieve this with RHF
    // As we're displaying errors only for touched fields, we need to mark them as touched
    for (const key of keysOf(errors)) {
      // TODO: Check how exactly am I supposed to deal with root errors in RHF
      if (key === 'root') continue
      // @ts-expect-error For whatever reason we can get an empty key here, TODO: Find out why and fix it
      if (key === '') continue
      tools.setValue(key, values[key], { shouldTouch: true })
    }
  }, [])

  const form = useActionForm({
    action: processDonationFormAction,
    actionContext: { returnToOnAuthErrorUrl: '' }, // this is not a secure action anyways, we don't care about the returnToOnAuthErrorUrl
    schema: donationFormSchema,
    getErrorMap: getDonationFormErrorMap,
    defaultValues,
    defaultErrorMessage: t('nextjs.errors.messages.default'),
    afterClientSubmitSuccess,
    afterClientSubmitError,
    afterSubmitSuccess,
    afterSubmitError,
    mode: 'all',
    reValidateMode: 'onChange',
    id,
  })

  // immediately trigger validation for the default values
  useEffect(() => {
    void form.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return form
}

export default useDonationForm
