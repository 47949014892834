import Field from '@/form/ActionForm/Field'
import type { DonationFormFieldProps } from './types'
import type { DonationFormValues } from '@/donationForm/types'
import type { FieldAsPropType } from '@/form/ActionForm/Field'
import type { FieldPath } from 'react-hook-form'

function DonationFormField<TField extends FieldPath<DonationFormValues>, TInput extends FieldAsPropType = 'input'>({
  ...props
}: DonationFormFieldProps<TField, TInput>) {
  return <Field {...props} />
}
export default DonationFormField
