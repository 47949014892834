'use client'

import { useCallback, useEffect } from 'react'
function useRescaleViewport() {
  const adjustViewportWidth = useCallback(() => {
    const metaTag = document.querySelector("meta[name='viewport']")
    if (!metaTag) return
    if (window.matchMedia('(min-device-width: 451px) and (max-device-width: 979px)').matches) {
      metaTag.setAttribute('content', 'width=1100')
    } else {
      metaTag.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no')
    }
  }, [])

  return adjustViewportWidth
}
function RescalingViewportHack() {
  const adjustViewportWidth = useRescaleViewport()
  useEffect(() => {
    document.addEventListener('DOMContentLoaded', adjustViewportWidth)
    window.addEventListener('resize', adjustViewportWidth)
    return () => {
      document.removeEventListener('DOMContentLoaded', adjustViewportWidth)
      window.removeEventListener('resize', adjustViewportWidth)
    }
  }, [adjustViewportWidth])
  return null
}

export default RescalingViewportHack
