import Autosuggest from 'react-autosuggest'
import { DonationFormValues } from '@/donationForm/types'
import { TextInput } from '@betterplace/design-system/client'
import { useCallback, useState } from 'react'
import { useController } from 'react-hook-form'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type { AddressFieldsProps, OnStreetSuggestionSelected, StreetSuggestion } from './types'
import type { RenderInputComponentProps, SuggestionsFetchRequested } from 'react-autosuggest'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderSuggestion = (v: any) => v as string

const getSuggestionValue = (s: StreetSuggestion) => s.suggestion

function useOnSuggestionFetchRequested({
  setSuggestions,
}: {
  setSuggestions: (suggestions: Array<StreetSuggestion>) => void
}) {
  const { getValues } = useDonationFormContext()
  const onSuggestionsFetchRequested: SuggestionsFetchRequested = useCallback(
    ({ value }) => {
      const [countryAlpha2, zip] = getValues(['country_alpha2', 'zip'])
      if (countryAlpha2 !== 'DE' || value.length < 1 || zip?.length !== 5) return
      void fetch(`${process.env.NEXT_PUBLIC_BETTERPLACE_URL}/de/zip_streets.json?zip=${zip}&street=${value}`).then(
        async (response) => {
          if (!response.ok) return
          const payload = (await response.json()) as { streets?: Array<StreetSuggestion> }
          if (!payload.streets) return
          setSuggestions(payload.streets)
        }
      )
    },
    [getValues, setSuggestions]
  )
  return onSuggestionsFetchRequested
}

function StreetField({ required }: AddressFieldsProps) {
  const t = useTranslations('nextjs.donate.attributes')
  const label = t('street')
  const { control } = useDonationFormContext()
  const { field, fieldState } = useController<DonationFormValues, 'street'>({
    name: 'street',
    control,
    rules: { required },
  })
  const { onChange } = field

  const [suggestions, setSuggestions] = useState<Array<StreetSuggestion>>([])

  const onSuggestionsClearRequested = useCallback(() => setSuggestions([]), [])
  const onSuggestionsFetchRequested = useOnSuggestionFetchRequested({ setSuggestions })
  const onSuggestionSelected: OnStreetSuggestionSelected = useCallback(
    (event, suggestion) => {
      if (suggestion.method === 'enter') event.preventDefault() // TODO Check why?
      onChange(String(suggestion.suggestion))
      // TODO originally we would then do something like this, let's see if we need it
      // void formik.setFieldValue('street', String(suggestion.suggestion) + ' ')
    },
    [onChange]
  )

  const renderInputComponent = useCallback(
    (inputProps: RenderInputComponentProps) => {
      return (
        <TextInput
          {...inputProps}
          label={label}
          required={required}
          error={fieldState.isTouched && fieldState.error ? fieldState.error.message : undefined}
        />
      )
    },
    [fieldState.error, fieldState.isTouched, label, required]
  )

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      inputProps={{ ...field }}
    />
  )
}

export default StreetField
