/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
let lastCallTime = 0

function wasCalledRecently() {
  const currentCallTime = new Date().getTime()
  const calledRecently = currentCallTime - lastCallTime < 2
  lastCallTime = currentCallTime
  return calledRecently
}

function withThrottle(
  fnThrottledCb: () => void | Promise<void>
): <TFunc extends (...args: any[]) => any>(fn: TFunc) => TFunc {
  return <TFunc extends (...args: any[]) => any>(fn: TFunc) =>
    ((...args: any[]) => {
      if (wasCalledRecently()) {
        void fnThrottledCb()
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return fn(...args)
    }) as TFunc
}

export default withThrottle
