'use client'
import CodonationCalculator from '@/donationForm/_dependencies/CodonationCalculator'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationForm/_dependencies/helpers'
import { useEffect } from 'react'

function useUpdateCodonationOnDonationChange() {
  const { formState, setValue } = useDonationFormContext()
  const {
    touchedFields: { codonation_cents: isTouched },
  } = formState
  const { showCodonation } = useDonationFormConfig()
  const [amountCents] = useDonationFormValues(['amount_cents'])
  useEffect(() => {
    if (isTouched) return
    if (showCodonation) {
      const codonationCents = CodonationCalculator.suggest(amountCents) ?? 0
      setValue('codonation_cents', codonationCents)
    }
  }, [amountCents, isTouched, setValue, showCodonation])
}

export default useUpdateCodonationOnDonationChange
