'use client'

import styles from './IFrameExplanation.module.css'
import { Heading } from '@betterplace/design-system/client'
import { useTranslations } from 'next-intl'

function IFrameExplanation() {
  const t = useTranslations('nextjs.donate.iframe')

  return (
    <div>
      <Heading level="h300" as="h2">
        {t('codonation_intro_title')}
      </Heading>
      <p className={styles.body}>{t('codonation_intro_body')}</p>
      <strong className={styles.callToAction}>{t('codonation_intro_cta')}</strong>
    </div>
  )
}

export default IFrameExplanation
