'use client'
import MessagesContext from './MessagesContext'
import { Message, MessagesContextType, MessagesProviderProps } from './types'
import { useCallback, useMemo, useState } from 'react'
function MessagesProvider({ locale, children, initialMessages }: MessagesProviderProps) {
  const [messages, setMessages] = useState<Array<Message>>(initialMessages ?? [])

  const addMessage: MessagesContextType['addMessage'] = useCallback(
    (message) => {
      const id = `${message.type}-${message.message?.toLocaleString()}`
      setMessages((prevMessages) => {
        const nextMessages = prevMessages.filter((msg) => msg.id !== id)
        nextMessages.push({ ...message, id, locale })
        return nextMessages
      })
      return id
    },
    [locale]
  )
  const removeMessage: MessagesContextType['removeMessage'] = useCallback((messageId) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId))
  }, [])

  const reset: MessagesContextType['reset'] = useCallback(() => {
    setMessages([])
  }, [])

  const contextValue = useMemo(
    () => ({
      messages,
      addMessage,
      removeMessage,
      reset,
      locale,
    }),
    [addMessage, locale, messages, removeMessage, reset]
  )
  return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>
}

export default MessagesProvider
