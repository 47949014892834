import { HotjarProps } from './types'
import { useEffect } from 'react'
/**
 * @todo Re-factor into a hook
 */
function Hotjar({ hotjarId }: HotjarProps) {
  useEffect(() => {
    if (!hotjarId) return
    if (hotjarId) {
      window.hj =
        window.hj || /** @type {typeof window.hj} */ ((...args) => (window.hj.q = window.hj.q || []).push(args))
      window._hjSettings = { hjid: hotjarId, hjsv: 6 }
      const head = document.getElementsByTagName('head')[0]
      const hotjarScript = document.createElement('script')
      hotjarScript.async = true
      hotjarScript.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`
      head?.appendChild(hotjarScript)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Hotjar
