'use client'

import CodonationSelection from '@/donationForm/_dependencies/components/DonationForm/formParts/CodonationSelection'
import DonationAmountSum from '@/donationForm/_dependencies/components/shared/DonationAmountSum'
import I18nHtml from '@/components/I18nHtml'
import Modal from '@/donationForm/_dependencies/components/shared/Modal'
import classNames from 'classnames'
import styles from './Summary.module.css'
import { Button, Heading } from '@betterplace/design-system/client'
import { SummaryProps } from './types'
import { toLowerCase } from '@betterplace/utils'
import { useCallback, useState } from 'react'
import { useDonationFormConfig } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function Explanation() {
  const t = useTranslations()
  const { receiverName, wording } = useDonationFormConfig()
  const [showWhyModal, setShowWhyModal] = useState(false)
  const openWhyModal = useCallback(() => {
    setShowWhyModal(true)
  }, [])
  const hideWhyModal = useCallback(() => {
    setShowWhyModal(false)
  }, [])

  return (
    <>
      <Heading level="h200" className="headline">
        {t('nextjs.donate.summary.headline')}
      </Heading>
      <p>
        <span>{t('nextjs.donate.summary.description_html')}</span>
        <button
          type="button"
          className={classNames('simulated-link', styles.explanationMoreInfoButton)}
          onClick={openWhyModal}
        >
          {t('nextjs.donate.codonation_selection.why_modal.open_button')}
        </button>
      </p>

      <Modal
        id="donation-summary-modal"
        onClose={hideWhyModal}
        isOpen={showWhyModal}
        title={t('nextjs.donate.codonation_selection.why_modal.headline')}
        closeText={t('nextjs.donate.codonation_selection.why_modal.close_button')}
        customActions={
          <Button kind="primary" size="small" onClick={hideWhyModal} type="button" className={styles.closeModalButton}>
            {t('nextjs.donate.codonation_selection.why_modal.close_button')}
          </Button>
        }
      >
        <I18nHtml
          as="div"
          i18nKey={`nextjs.donate.codonation_selection.why_modal.${toLowerCase(wording)}_html`}
          options={{ receiver_name: receiverName }}
        />
      </Modal>
    </>
  )
}

function Summary(props: SummaryProps) {
  const t = useTranslations()
  const { showCodonation } = useDonationFormConfig()

  if (!showCodonation) return null

  return (
    <>
      {props.explanation || <Explanation />}
      <div className={styles.donationSummaryContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={classNames(styles.cell, styles.codonationSelectionCell)}>
                <CodonationSelection />
              </td>
              <td className={classNames(styles.cell, styles.codonationAmountSumCell)}>
                <span className={styles.codonationAmountSumContent}>
                  <span>+ </span>
                  <DonationAmountSum precision={2} fields={['codonation_cents']} />
                </span>
              </td>
            </tr>
            <tr className={styles.row}>
              <td colSpan={2} className={classNames(styles.cell, styles.totalDonationAmountSumCell)}>
                <strong className={styles.totalDonationAmountSumContent}>
                  <span>{t('nextjs.donate.summary.total')}</span>{' '}
                  <DonationAmountSum precision={2} fields={['amount_cents', 'codonation_cents']} />
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Summary
