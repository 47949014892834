'use client'
import DonationFormConfigContext from '@/donationForm/_dependencies/DonationFormConfigContext'
import type { DonationFormConfig } from '@/donationForm/types'
import type { ReactNode } from 'react'

function DonationFormConfigProvider({ children, config }: { children: ReactNode; config: DonationFormConfig }) {
  return <DonationFormConfigContext.Provider value={config}>{children}</DonationFormConfigContext.Provider>
}

export default DonationFormConfigProvider
