import { PostHogConfig } from 'posthog-js'
import type { ReceiverTypes } from '@betterplace/api-graphql-types'

type AnalyticsFn<Data extends {}> = (data: Data) => void

export type ReceiverIdentification = {
  id: string
  receiver_type: ReceiverType | ReceiverTypes
}

export type DonationFormReceiverIdentification = ReceiverIdentification & { donation_form_type: DonationFormType }

export type DonationData = {
  donation_amount: number
  recurring_donation: string
  payment_method: string
  codonation_amount: number
  show_amount_selected: boolean
  show_name_selected: boolean
  donation_receipt_requested: boolean
  platform_newsletter_selected: boolean
  comment_added: boolean
}

export type DonationCompletedData = DonationFormReceiverIdentification &
  DonationData & { postdonation_redirect_url: string }

export type DonationSubmittedData = DonationFormReceiverIdentification & DonationData

export type DonationErrorMessageDisplayed = DonationFormReceiverIdentification & {
  error: string
  errors: object
}

export type DonationButtonClickedData = ReceiverIdentification & {
  button_location: DonateButtonLocation
}

export type DonationAmountChangedData = DonationFormReceiverIdentification & {
  previous_donation_amount: number
  new_donation_amount: number
  selection_type: 'donation_amount_button' | 'donation_own_amount'
}

export type Config = {
  apiKey: string
  enable: boolean
  debug?: boolean
  bootstrapData?: PostHogConfig['bootstrap']
}

export type ReceiverType = 'Project' | 'FundraisingEvent'

export type DonateButtonPPPLocation = 'PPP Main' | 'PPP Sticky' | 'PPP MatchingEvent'
export type DonateButtonFunELocation = 'FunE Main' | 'FunE Share' | 'FunE Sticky'
export type DonateButtonLocation = DonateButtonPPPLocation | DonateButtonFunELocation
export type CleanupFunction = () => void
export type FeatureFlagValue = string | boolean | undefined
export type FeatureFlagCallback = (value: FeatureFlagValue) => void
export interface Analytics {
  initialize(cfg: Config): void
  isInitialized(): boolean
  getDistinctId(): string
  optOut(): void
  optIn(): void
  optInOutPending(): void
  reset(): void
  getFeatureFlag(name: FeatureFlagKey): FeatureFlagValue
  listenToFeatureFlag(name: FeatureFlagKey, callback: FeatureFlagCallback): CleanupFunction
  donationButtonClicked: AnalyticsFn<DonationButtonClickedData>
  donationFormOpened: AnalyticsFn<DonationFormReceiverIdentification>
  coDonationModalOpened: AnalyticsFn<DonationFormReceiverIdentification>
  donationSubmitted: AnalyticsFn<DonationSubmittedData>
  donationCompleted: AnalyticsFn<DonationCompletedData>
  donationErrorMessageDisplayed: AnalyticsFn<DonationErrorMessageDisplayed>
  coDonationSetToZero: AnalyticsFn<DonationFormReceiverIdentification>
}

export type FeatureFlagKey = 'new-default-amount-4-donation-amount-suggestions'
export type FeatureFlagData = Partial<Record<FeatureFlagKey, string | boolean>>
export type PosthogBootstrapDataCookieValue = {
  distinctID: string
  featureFlags: Record<string, string | boolean>
}

export const DonationFormType = {
  Project: 'Project',
  FundraisingEvent: 'FundraisingEvent',
  WirWunderWithoutCodonation: 'WirWunder without CD',
  WirWunderWithCodonation: 'WirWunder with CD',
  OtherClients: 'OtherClients',
  Iframe: 'iframe',
} as const

export type DonationFormType = (typeof DonationFormType)[keyof typeof DonationFormType]
