'use client'

import useLocale from '@/i18n/useLocale'
import { type AmountFieldProps } from './types'
import { Icon } from '@betterplace/design-system/server'
import { TextInput } from '@betterplace/design-system/client'
import { formatCents, parseCents, sanitizeMoney } from '@betterplace/utils'
import { useCallback, useState } from 'react'
import { useController } from 'react-hook-form'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'
import { useIsMounted } from '@/helpers/hooks'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'

const AMOUNT_FIELD_NAME = 'amount_cents'

function DonationAmountField({
  hideError = false,
  min,
  max,
  maxLength,
  minLength,
  pattern: _,
  onBlur: onBlur_,
  required,
  className,
  invisibleLabel,
  ...props
}: AmountFieldProps) {
  const {
    control,
    formState: { errors },
  } = useDonationFormContext()
  const locale = useLocale()
  const {
    field: { onChange: onChange_, onBlur: __, value: value_, ...field },
  } = useController<DonationFormValues, 'amount_cents'>({
    control,
    name: 'amount_cents',
    rules: { min, max, maxLength, minLength, required },
  })
  const t = useTranslations('nextjs.donate.attributes')
  const mounted = useIsMounted()

  const [value, setValue] = useState(formatCents(locale, value_, { format: '%v' }))

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      setValue(sanitizeMoney(target.value))
      const next = parseCents(target.value)
      if (next === value_) return
      onChange_(next)
    },
    [onChange_, value_]
  )
  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target.value) {
        onChange_(0)
        setValue(formatCents(locale, 0, { format: '%v' }))
      }
      onBlur_?.(event)
    },
    [locale, onBlur_, onChange_]
  )
  const errorDisplay = hideError ? 'border' : 'all'
  return (
    <TextInput
      {...props}
      {...field}
      error={errors[AMOUNT_FIELD_NAME]?.message}
      errorDisplay={errorDisplay}
      className={className}
      inputSize="large"
      type="text"
      inputMode="decimal"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled_={!mounted}
      label={t('amount_cents')}
      invisibleLabel={invisibleLabel}
      required={required}
    >
      <Icon name="euroSign" title="Euro" color="fg-content-secondary" id={`${AMOUNT_FIELD_NAME}-suffix`} />
    </TextInput>
  )
}

export { AMOUNT_FIELD_NAME }

export default DonationAmountField
