'use client'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import { TRACKING_COOKIE_NAME, TrackingCookieType } from './types'
import { useCallback, useEffect, useState } from 'react'
const cookies = new Cookies()
function useTrackingCookie(serverSideCookieValue: TrackingCookieType) {
  const [trackingCookieValue, setTrackingCookieValue] = useState<TrackingCookieType>(serverSideCookieValue)

  const handleCookieChange: CookieChangeListener = useCallback((cookie) => {
    if (cookie.name !== TRACKING_COOKIE_NAME) {
      return
    }
    setTrackingCookieValue(cookie.value as TrackingCookieType)
  }, [])

  const setCookie = useCallback((value: TrackingCookieType, domain: string) => {
    cookies.set(TRACKING_COOKIE_NAME, value, {
      path: '/',
      expires: new Date(2099, 1, 1),
      domain: `.${domain}`,
    })
    setTrackingCookieValue(value)
  }, [])

  useEffect(() => {
    const value = cookies.get<TrackingCookieType>(TRACKING_COOKIE_NAME)
    setTrackingCookieValue(value)
    cookies.addChangeListener(handleCookieChange)
    return () => {
      cookies.removeChangeListener(handleCookieChange)
    }
  }, [handleCookieChange])

  return [trackingCookieValue, setCookie] as const
}

export default useTrackingCookie
