'use client'

import { noop } from 'lodash'
import { useEffect } from 'react'

function IFrameResizer() {
  useEffect(() => {
    // @ts-expect-error 'iframe-resizer/js/iframeResizer.contentWindow' has no type definitions
    void import('iframe-resizer/js/iframeResizer.contentWindow').catch(noop)
  }, [])
  return null
}

export default IFrameResizer
