'use client'

import DonationFormField from '../DonationFormField'
import { DonationFormValues } from '@/donationForm/types'
import { RequiredFieldProps } from './types'
import type { FieldAsPropType } from '@/form/ActionForm/Field'
import type { FieldPath } from 'react-hook-form'

function RequiredField<TField extends FieldPath<DonationFormValues>, TInput extends FieldAsPropType = 'input'>(
  props: RequiredFieldProps<TField, TInput>
) {
  return <DonationFormField {...props} label={`${props.title}*`} required />
}

export default RequiredField
