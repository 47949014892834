'use client'
import ErrorMessage from '@/donationForm/_dependencies/components/shared/ErrorMessage'
import styles from '../StripeFields.module.css'
import useResetFieldOnPaymentMethodChange from '../useResetFieldOnPaymentMethodChange'
import { Heading } from '@betterplace/design-system/client'
import { IbanElement } from '@stripe/react-stripe-js'
import { IbanElementChangeHandler, IbanElementStripeProps } from './types'
import { NextIntlKeys, Translator } from '@/i18n/types'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { StripeElementStyle, StripeIbanElement } from '@stripe/stripe-js'
import { default as coreTokens } from '@betterplace/design-tokens/core.json'
import { getTranslatorWithFallback } from '@/i18n'
import { default as semanticTokens } from '@betterplace/design-tokens/semantic.json'
import { useCallback, useMemo } from 'react'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationForm/_dependencies/helpers'
import { useIsStripeLoaded } from '@/donationForm/_dependencies/StripeContext'
import { useTranslations } from 'next-intl'

const fgContentPrimaryColor = semanticTokens['betterplace-color-fg-content-primary']
const fgMuteColor = semanticTokens['betterplace-color-fg-mute']
const fgDangerColor = semanticTokens['betterplace-color-fg-danger']
const fontWeight = coreTokens['betterplace-font-weights-regular']

/**
 * Using rem for lineHeight and fontSize produces unexpected results:
 * - not right font size
 * - or if both of them are set the input height grows to 3x bigger
 */

const style: StripeElementStyle = {
  base: {
    'color': fgContentPrimaryColor,
    'lineHeight': '24px',
    'fontFamily': '"Fira Sans", Helvetica, sans-serif', // Fira Sans will only work once we enable it the element options
    'fontSmoothing': 'antialiased',
    'fontSize': '16px',
    'fontWeight': fontWeight,
    '::placeholder': {
      color: fgMuteColor,
    },
  },
  invalid: {
    color: fgDangerColor,
    iconColor: fgDangerColor,
  },
}

const ibanElementProps: IbanElementStripeProps = {
  options: {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'DE',
    style,
  },
}

type DonateNextIntlKeys = NextIntlKeys<'nextjs.donate'>

function StripeSEPADebitFields() {
  const t_ = useTranslations('nextjs.donate')
  const t = useMemo(() => getTranslatorWithFallback<Translator<'nextjs.donate'>>(t_), [t_])

  const { stripePublicKey } = useDonationFormConfig()
  const { setError, setValue, setBusy, clearErrors } = useDonationFormContext()
  const [paymentMethod] = useDonationFormValues(['payment_method'])

  const handleChange: IbanElementChangeHandler = useCallback(
    (event) => {
      setValue('stripe_token', stripePublicKey, { shouldTouch: true })
      if (!event.error) {
        return clearErrors('stripe_token')
      }
      setError('stripe_token', {
        message: t(`errors.stripe_sepa_debit_${event.error.code}` as DonateNextIntlKeys, {
          defaultValue: t('errors.unknown_error'),
        }),
      })
      setBusy(false)
    },
    [stripePublicKey, setError, setValue, setBusy, clearErrors, t]
  )
  const onReady = useResetFieldOnPaymentMethodChange<StripeIbanElement>(paymentMethod)
  const isStripeLoaded = useIsStripeLoaded()
  const show = paymentMethod === PaymentMethods.StripeSepaDebit
  if (!isStripeLoaded)
    return (
      <div className={styles.stripeNotLoadedMessage}>
        <Heading level="h300" className={styles.label}>
          {t('sepa_fields.headline')}
        </Heading>
        <p>{t('errors.stripe_sepa_debit_unavailable')}</p>
      </div>
    )

  // beacuse of the new library's behaviour unmounting Stripe components will cause an exception,
  // unless it's removed together with it's provider
  return (
    <div style={{ display: show ? 'block' : 'none' }} className={styles.stripeField}>
      <Heading level="h300" className={styles.label}>
        {t('sepa_fields.headline')}*
      </Heading>
      <IbanElement onReady={onReady} onChange={handleChange} {...ibanElementProps} />
      <ErrorMessage name="stripe_token" className={styles.stripeFieldError} />
    </div>
  )
}

export default StripeSEPADebitFields
