import CodonationReasons from './CodonationReasons'
import CodonationSelection from './CodonationSelection'
import IconHeartPin from '@betterplace/assets/images/icons/heartpin.svg'
import Image, { StaticImageData } from 'next/image'
import styles from './CodonationBox.module.css'
import { Heading } from '@betterplace/design-system/client'
import { useDonationFormConfig } from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'
function CodonationBox() {
  const t = useTranslations('nextjs.donate.codonation_box')
  const { showCodonation } = useDonationFormConfig()
  if (showCodonation === false) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.iconWrapper}>
          <Image src={IconHeartPin as StaticImageData} alt="" className={styles.icon} />
        </div>
        <Heading level="h200" className={styles.headline}>
          {t('headline')}
        </Heading>
        <span className={styles.subline}>{t('subline')}</span>
        <CodonationReasons />
        <CodonationSelection />
      </div>
    </div>
  )
}
export default CodonationBox
