'use client'

import analytics, { PosthogBootstrapDataCookieValue } from '@betterplace/product-analytics'
import { ReactNode } from 'react'

const shouldRunInThisEnvironment =
  !!process.env.NEXT_PUBLIC_ENABLE_POSTHOG ||
  !!process.env.ENABLE_POSTHOG ||
  (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT !== 'test')

const debug = !process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT.includes('production')

function ClientAnalyticsProvider({
  apiKey,
  bootstrapData,
  children,
}: {
  bootstrapData?: PosthogBootstrapDataCookieValue
  children: ReactNode
  apiKey: string
}) {
  // !!WARNING!! This will run on every render, so make sure to early exit in initialize
  analytics.initialize({
    bootstrapData,
    apiKey,
    debug,
    enable: typeof window !== 'undefined' && shouldRunInThisEnvironment,
  })
  return children
}

export default ClientAnalyticsProvider
