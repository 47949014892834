import classNames from 'classnames'
import styles from './ErrorMessage.module.css'
import { ErrorMessageProps } from './types'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'

function ErrorMessage({ name, className }: ErrorMessageProps) {
  const {
    formState: { errors, touchedFields },
  } = useDonationFormContext()

  if (!touchedFields[name] || !errors[name]?.message) {
    return null
  }
  return (
    <div className={classNames(styles.helpInline, className)} id={`${name}-error`}>
      {errors[name]?.message}
    </div>
  )
}

export default ErrorMessage
