import styles from './DirectDepositInfo.module.css'
import { PaymentMethodProps } from '../types'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { useTranslations } from 'next-intl'

function DirectDepositInfo({ paymentMethod }: PaymentMethodProps) {
  const t = useTranslations()
  if (paymentMethod !== PaymentMethods.DirectDeposit) return null
  return (
    <div className={styles.directDepositCard}>
      <p className={styles.directDepositText}>{t('nextjs.donate.direct_deposit_info')}</p>
    </div>
  )
}

export default DirectDepositInfo
