import { type FocusEventHandler, useCallback } from 'react'
import { useDonationFormContext } from '@/donationForm/_dependencies/helpers'

function useCityAutoFill(): FocusEventHandler<HTMLInputElement> {
  const { setValue, getValues } = useDonationFormContext()
  return useCallback(() => {
    const [countryAlpha2, zip] = getValues(['country_alpha2', 'zip'])
    if (countryAlpha2 === 'DE' && zip?.length === 5) {
      void fetch(`${process.env.NEXT_PUBLIC_BETTERPLACE_URL}/de/zip_cities/${zip}.json`).then(async (response) => {
        if (!response.ok) return
        const payload = (await response.json()) as { city?: string }
        if (!payload.city) return
        setValue('city', payload.city, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
      })
    }
  }, [getValues, setValue])
}

export default useCityAutoFill
