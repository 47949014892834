'use client'

import { PaymentMethods } from '@betterplace/api-graphql-types'
import { useCallback, useEffect, useRef } from 'react'

function useResetFieldOnMethodChange<T extends { clear(): void }>(currentMethod: PaymentMethods) {
  const eleRef = useRef<T | null>(null)
  const onReady = useCallback((ele: T) => {
    eleRef.current = ele
  }, [])
  useEffect(() => {
    if (!eleRef.current) return
    eleRef.current.clear()
  }, [currentMethod])
  return onReady
}

export default useResetFieldOnMethodChange
