'use client'

import useNetworkStatus from '../useNetworkStatus'
import { useEffect, useRef } from 'react'
import { useMessageContext } from '@/components/Messages'
import { useTranslations } from 'next-intl'

function useOfflineMessage() {
  const t = useTranslations()
  const networkStatus = useNetworkStatus()
  const { addMessage, removeMessage } = useMessageContext()
  const messageRef = useRef<string | null>(null)
  useEffect(() => {
    if (networkStatus === 'offline') {
      messageRef.current = addMessage({ type: 'warning', message: t('nextjs.errors.messages.offline') })
      return
    }
    messageRef.current && removeMessage(messageRef.current)
    return () => {
      messageRef.current && removeMessage(messageRef.current)
    }
  }, [addMessage, networkStatus, removeMessage, t])
}

export default useOfflineMessage
