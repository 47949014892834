import styles from './TaxDeductibilityNotice.module.css'
import { Tooltip } from '@betterplace/design-system/client'
import { useTranslations } from 'next-intl'

function TaxDeductibilityNotice() {
  const t = useTranslations('nextjs.donate.iframe')
  return (
    <div className={styles.taxDeductibilityNotice}>
      <Tooltip content={t('tax_deductibility_notice_tooltip')} id="tooltip-for-tax-deductibility">
        <span className="simulated-link">{t('tax_deductibility_notice')}</span>
      </Tooltip>
    </div>
  )
}
export default TaxDeductibilityNotice
